.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  display: flex;

  justify-content: center;
  

}

.heading {
  font-family: 'Russo One';
font-weight: 400;
font-size: 64px;
line-height: 52px;
margin: 24px 0;
text-align: center;
text-transform: uppercase;
color: #C09F57;
}

.heading2 {
font-weight: 700;
font-size: 35px;
line-height: 45px;
text-transform: uppercase;
color: #FFFFFF;
margin: 0 0 10px 0;

}

.heading3 {
font-weight: 700;
font-size: 35px;
line-height: 45px;
text-transform: uppercase;
color:  #C09F57;
margin: 0;
}
.body1 {
font-family: 'Roboto';
font-weight: 700;
font-size: 20px;
color: #FFFFFF;

}
.body2 {
font-family: 'Roboto';
font-weight: 400;
font-size: 17px;
color: #FFFFFF;
}

.productTitle {
    font-size: 8px;
    margin: 0 0 8px;
    text-transform: uppercase;
    font-family: 'Roboto';
    color: #000;
    margin-top: 10px;
    font-weight: 600;
 
}
.price{
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    font-size: 8px;
    color: #787878;
}

.qty{
font-weight: 700;
font-size: 24px;
line-height: 55px;
text-align: center;
text-transform: uppercase;
color: #FFFFFF;
margin-bottom: 10px;
}

.pointer {
  cursor: pointer;
}

.error {
  color: #d66969;
}

.spacing-0 {
  letter-spacing: 0!important;
}

